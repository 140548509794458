import _ from 'lodash'

export default class DataSource {
  getCastCols(): { [key: string]: string } {
    return {}
  }

  castSortModel(sortModel: any) {
    const castRows = this.getCastCols()
    return _.mapValues(
      _.keyBy(sortModel, obj => castRows[obj.colId] || obj.colId),
      'sort'
    )
  }

  castFilterModel(filterModel: { [key: string]: any }) {
    const castRows = this.getCastCols()
    const filter: { [key: string]: any } = {}
    for (const [key, value] of Object.entries(filterModel)) {
      filter[castRows[key] || key] = value
    }
    return filter
  }
}
