import { http } from '@/api/requests/httpAxios'
import { AxiosPromise } from 'axios'
import createURLParams from '@/api/datasource/createURLParams'

export default class StudentETUAPI {
  public static getAllStudentsETU(
    limit: number,
    offset: number,
    sort: { [k: string]: any } | null,
    filter: { [k: string]: any } | null
  ): AxiosPromise {
    const params = createURLParams(limit, offset, sort, filter)
    return http.get('/lk-etu/students', { params })
  }

  public static inviteStudentETUToClasses(ids: any): AxiosPromise {
    return http.put('/lk-etu/students/', {
      lkIds: ids
    })
  }
}
