import { IGetRowsParams } from 'ag-grid-community'
import DataSource from '@/api/datasource/datasource'
import StudentETUAPI from '@/api/requests/studentETU'

export class StudentsETUDataSource extends DataSource {
  getRows(params: IGetRowsParams) {
    const limit = params.endRow - params.startRow
    const offset = params.startRow
    const sort = this.castSortModel(params.sortModel)
    const filter = this.castFilterModel(params.filterModel)

    StudentETUAPI.getAllStudentsETU(limit, offset, sort, filter).then(
      ({ data }) => {
        params.successCallback(data.studentsLk, data.count)
      }
    )
  }
}
