

































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { AgGridVue } from 'ag-grid-vue'
import { GridApi } from 'ag-grid-community'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { StudentsETUDataSource } from '@/api/datasource/studentsETU'
import AgGridFactory from '@/factories/agGridFactory'
import ActionRenderer from '@/components/table/ActionRenderer.vue'
import dateFormat from '@/config/default/dateFormat'
import StudentETUAPI from '@/api/requests/studentETU'

@Component({
  components: {
    AgGridVue,
    ActionRenderer
  }
})
export default class StudentsETUList extends Vue {
  private selectedStudents: any[] = []
  private dataSource: StudentsETUDataSource | undefined
  private gridApi?: GridApi
  private columnDefs = [
    {
      headerName: 'Фамилия',
      field: 'surname',
      colId: 'surname',
      checkboxSelection: true,
      width: 160
    },
    {
      headerName: 'Имя',
      field: 'name',
      colId: 'name',
      width: 120
    },
    {
      headerName: 'Отчество',
      field: 'midname',
      colId: 'midname',
      width: 160
    },
    {
      headerName: 'Почта',
      field: 'email',
      colId: 'email',
      width: 160
    },
    {
      headerName: 'Группа',
      field: 'group',
      colId: 'group',
      width: 60
    },
    {
      headerName: 'Направление',
      field: 'direction',
      colId: 'direction',
      tooltipField: 'direction',
      tooltipComponentParams: {
        tooltipShowDelay: 1000
      }
    },
    {
      headerName: 'Дата рождения',
      field: 'birthday',
      colId: 'birthday',
      filter: 'agDateColumnFilter',
      width: 86,
      suppressSizeToFit: false,
      filterParams: {
        buttons: ['reset']
      },
      valueFormatter: ({ value }: any) =>
        isEmpty(value)
          ? 'Не указано'
          : moment(value).format(dateFormat.date.localFormat)
    },
    {
      headerName: 'Доступ к системе',
      field: 'systemUser',
      colId: 'systemUser',
      width: 60,
      suppressSizeToFit: false,
      filterParams: {
        buttons: ['reset']
      },
      valueFormatter({ value }: any) {
        return isEmpty(value) ? 'Нет' : 'Да'
      }
    }
  ]

  private async created() {
    this.dataSource = new StudentsETUDataSource()
  }

  private gridOptions = {
    ...AgGridFactory.getDefaultGridOptions(),
    defaultColDef: {
      sortable: false,
      editable: false,
      filter: true,
      resizable: false,
      filterParams: {
        buttons: ['reset'],
        suppressAndOrCondition: true
      }
    },
    rowSelection: 'multiple',
    suppressRowClickSelection: true,
    pagination: true,
    paginationPageSize: 15,
    rowModelType: 'infinite',
    onGridReady: this.onGridReady,
    frameworkComponents: {
      ActionRenderer
    }
  }

  private onGridReady({ api }: { api: GridApi }) {
    this.gridApi = api
  }

  private async onConfirm() {
    await StudentETUAPI.inviteStudentETUToClasses(this.selectedStudents).then(
      () => {
        this.gridApi?.purgeInfiniteCache()
        this.gridApi?.deselectAll()
      }
    )
  }

  private onSelectionChange() {
    this.selectedStudents =
      this.gridApi?.getSelectedRows().map(el => el.lkId) ?? []
  }
}
